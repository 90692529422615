import Video from "./Video"

export default class Exercice {
    id: string
    name: string
    description: string
    reps: number
    series: number
    rest: number
    video: Video

    constructor(id: string, video: Video, name: string, description: string, reps: number, series: number, rest: number) {
        this.id = id;
        this.video = video;
        this.name = name;
        this.description = description;
        this.reps = reps;
        this.series = series;
        this.rest = rest;
    }

    static mapFromFIR(json: any) {        
        return new Exercice(
            json.id,
            json.video,
            json.name,
            json.description,
            json.reps,
            json.series,
            json.rest,
        );
    }
}