import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';

const firebase = require("firebase/app");
require("firebase/auth");

const uiConfig = {
    callbacks: {},
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
};

export default class Auth extends React.Component {
    render() {
        return (
            <div>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
        );
    }
}
