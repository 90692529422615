import React from 'react';
import { Button, Typography, Card, CardContent } from '@material-ui/core';
import { MenuType } from '../App';

import { withTranslation } from 'react-i18next';
class HomeScreen extends React.Component<any> {

    redirect = (type: MenuType) => () => {
        window.location.href = type;
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 250 }} onClick={this.redirect(MenuType.Exercices)}>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>{t('exercices.title')}</Typography>
                        <Button variant="contained" color="primary">{t('go')}</Button>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 250 }} onClick={this.redirect(MenuType.History)}>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>{t('history.title')}</Typography>
                        <Button variant="contained" color="primary">{t('go')}</Button>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 250 }} onClick={this.redirect(MenuType.Users)}>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>{t('users.title')}</Typography>
                        <Button variant="contained" color="primary">{t('go')}</Button>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 250 }} onClick={this.redirect(MenuType.Videos)}>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>{t('videos.title')}</Typography>
                        <Button variant="contained" color="primary">{t('go')}</Button>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 250 }} onClick={this.redirect(MenuType.Areas)}>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>{t('areas.title')}</Typography>
                        <Button variant="contained" color="primary" >{t('go')}</Button>
                    </CardContent>
                </Card>
            </>
        );
    }
}

export default withTranslation()(HomeScreen)