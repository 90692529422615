import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const en = require('./locales/en.json');
const fr = require('./locales/fr.json');
const it = require('./locales/it.json');

// the translations
const resources = {
  en: { translation: en },
  fr: { translation: fr },
  it: { translation: it },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;