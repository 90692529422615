export default class Video {
    id: string
    name: string
    title: string
    thumbnail: string//base64
    updatedAt: number//timestamp
    tags: string[]

    disable: boolean = false
    width: number = 0
    height: number = 0
    duration: number = 0
    length: number = 0
    type: string = ''

    constructor(id: string, name: string, title: string, thumbnail: string, updatedAt: number, tags: string[], disable: boolean) {
        this.id = id;
        this.name = name;
        this.title = title;
        this.thumbnail = thumbnail;
        this.updatedAt = updatedAt;
        this.tags = tags;
        this.disable = disable;
    }

    static mapFromFIR(json: any) {
        return new Video(
            json.id,
            json.name,
            json.title,
            json.thumbnail,
            json.updatedAt,
            json.tags,
            json.disable || false
        );
    }
}