export default class UserProfile {
    id: string
    painLocation: string[]
    motorPreference: string[]
    phase: number
    date: number

    constructor(id: string, painLocation: string[], motorPreference: string[], phase: number, date: number) {
        this.id = id;
        this.painLocation = painLocation;
        this.motorPreference = motorPreference;
        this.phase = phase;
        this.date = date;
    }

    static fake() {
        return new UserProfile("", [], [], 0, 0);
    }
}