import React from 'react';
import { Typography, Divider, Card, CardContent, Chip, Button, Dialog, IconButton, Toolbar, List, ListItem, DialogContent, DialogActions, TextField } from '@material-ui/core';
import Exercice from '../models/Exercice';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Video from '../models/Video';
import { withTranslation } from 'react-i18next';

var firebase = require("firebase/app");

export class ExerciceCard extends React.Component<any> {

    render() {
        const { exercice, onClick, onPlay, fullWidth } = this.props;

        return <div>
            <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: fullWidth ? 'inherit' : 275 }} onClick={() => { onClick(exercice) }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom>{exercice.name}</Typography>
                    <Divider light={true} />
                    <Typography color="textSecondary" style={{ marginTop: 10, marginBottom: 10 }}>
                        <Chip
                            size="small"
                            label="# séries"
                            variant="default"
                            color="default"
                            style={{ marginRight: 5 }}
                        />
                        <Chip
                            size="small"
                            label={exercice.series}
                            variant="default"
                            color="primary"
                            style={{ marginRight: 5 }}
                        />
                    </Typography>
                    <Typography color="textSecondary" style={{ marginTop: 10, marginBottom: 10 }}>
                        <Chip
                            size="small"
                            label="# réps"
                            variant="default"
                            color="default"
                            style={{ marginRight: 5 }}
                        />
                        <Chip
                            size="small"
                            label={exercice.reps}
                            variant="default"
                            color="primary"
                        />
                    </Typography>
                    <Typography color="textSecondary" style={{ marginTop: 10, marginBottom: 10 }}>
                        <Chip
                            size="small"
                            label="# repos"
                            variant="default"
                            color="default"
                            style={{ marginRight: 5 }}
                        />
                        <Chip
                            size="small"
                            label={exercice.rest}
                            variant="default"
                            color="primary"
                        />
                    </Typography>
                    -
                    <div style={{ margin: 10, marginBottom: 0 }}>
                        {exercice.video && <Chip
                            size="small"
                            icon={<PlayArrowIcon />}
                            label={exercice.video.name}
                            variant="default"
                            color="primary"
                            onClick={onPlay}
                        />}
                    </div>
                </CardContent>
            </Card>
        </div>;
    }
}
class ExerciceScreen extends React.Component<any> {
    _name: any;
    _description: any;
    _reps: any;
    _rest: any;
    _series: any;

    state = {
        showModal: false,
        videos: [],
        search: [],
        videoUrl: '',
        form: {
            uid: null,
            name: null,
            description: null,
            series: 0,
            reps: 0,
            rest: 0,
            video: null,
        }
    }

    async componentDidMount() {
        const docs = await firebase.firestore().collection("videos").get();
        var videos: Video[] = [];
        docs.forEach((p: any) => {
            videos.push(Video.mapFromFIR({ id: p.id, ...p.data() }));
        });

        this.setState({ videos, search: videos });
    }

    showExoForm = () => {
        this.setState({ showModal: true });
    }

    addExo = () => {
        if (!this._name.value || !this._description.value || !this._reps.value || !this._series.value || !this.state.form.video) return;

        const data = {
            name: this._name.value,
            description: this._description.value,
            series: this._series.value,
            reps: this._reps.value,
            rest: this._rest.value,
            videoId: this.state.form.video,
        }

        if (this.state.form.uid)
            firebase.firestore().collection("exercices").doc(this.state.form.uid).set(data, { merge: true });
        else
            firebase.firestore().collection("exercices").add(data);

        this.hideModal();
    }

    hideModal = () => {
        this.setState({ showModal: false, form: {} });
    }

    showEditExercice = (exercice: Exercice) => {
        this.setState({
            showModal: true,
            form: {
                uid: exercice.id,
                name: exercice.name,
                description: exercice.description,
                series: exercice.series,
                reps: exercice.reps,
                rest: exercice.rest,
                video: exercice.video?.id,
            }
        });
    }

    changeVideo = (video: any) => () => {        
        this.setState({ form: { ...this.state.form, video: video.id } });
    }

    hideVideo = () => {
        this.setState({ videoUrl: '' })
    }

    play = (e: any, videoId: string) => {
        e.stopPropagation();
        var storageRef = firebase.storage().ref();
        const ref = storageRef.child(`videos/${videoId}_landscape.webm`);
        ref.getDownloadURL().then((url: string) => {
            this.setState({ videoUrl: url })
        });
    }

    searchVideo = (e: any) => {
        const search = e.target.value.toLowerCase();
        var results = this.state.videos;
        if (!!search) {
            results = this.state.videos.filter((v: Video) => 
                v.name.toLowerCase().includes(search) || (v.tags || []).join(",").includes(search)
            );
        }
        this.setState({ search: results });
    }

    render() {
        const { search, form, videoUrl } = this.state;
        const { t } = this.props;        

        return <>
            <Dialog open={!!videoUrl} onBackdropClick={this.hideVideo} fullWidth>
                <video controls autoPlay loop>
                    <source src={videoUrl} type="video/mp4" />
                    {t('videos.incompatible')}
                </video>
            </Dialog>
            <Dialog open={this.state.showModal} onBackdropClick={this.hideModal} fullWidth>
                <Toolbar style={{ justifyContent: "flex-end" }}><IconButton edge="start" onClick={this.hideModal}><CloseIcon /></IconButton></Toolbar>
                <DialogContent style={{ textAlign: 'center' }}>
                    <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{t('exercices.name')}</Typography>
                        <TextField inputRef={r => { this._name = r }} defaultValue={form.name} inputProps={{ maxLength: 20 }} />
                    </div>
                    <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{t('exercices.description')}</Typography>
                        <TextField multiline inputRef={r => { this._description = r }} defaultValue={form.description} rowsMax={5} fullWidth />
                    </div>
                    <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ margin: 10 }}>
                            <Typography variant="h6" component="h2" color="primary">{t('exercices.series')}</Typography>
                            <TextField type='number' inputRef={r => { this._series = r }} defaultValue={form.series} fullWidth />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Typography variant="h6" component="h2" color="primary">{t('exercices.reps')}</Typography>
                            <TextField type='number' inputRef={r => { this._reps = r }} defaultValue={form.reps} fullWidth />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Typography variant="h6" component="h2" color="primary">{t('exercices.rest')}</Typography>
                            <TextField type='number' inputRef={r => { this._rest = r }} defaultValue={form.rest} fullWidth />
                        </div>
                    </span>
                    <div style={{ margin: 10, marginBottom: 40 }}>
                        <Typography variant="h6" component="h2" color="primary">{t('videos.title')}</Typography>
                        <TextField
                            label={t("videos.search")}
                            style={{ margin: 20 }}
                            onChange={this.searchVideo}
                            fullWidth
                        />
                        <List style={{ maxHeight: 200, overflow: 'auto' }}>
                            {search.sort((a: Video, b: Video) => a.name.localeCompare(b.name)).map((v: Video) => (
                                <ListItem style={{ justifyContent: 'space-between' }}>
                                    <Button size="small" onClick={(e: any) => { this.play(e, v.name) }}>
                                        <img src={v.thumbnail} width={50} height={50} style={{ borderRadius: 10 }} />
                                    </Button>
                                    <span style={{ marginLeft: 20, marginRight: 20 }}>{v.name}</span>
                                    <span style={{ marginLeft: 20, marginRight: 20 }}>
                                        {(v.tags || []).map(t => <Chip
                                            key={t}
                                            size="small"
                                            label={t}
                                            variant="default"
                                            color="primary"
                                            style={{ marginRight: 5 }}
                                        />)}
                                    </span>
                                    <Button size="small" onClick={this.changeVideo(v)}>
                                        {v.id === form.video ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </DialogContent>
                <DialogActions style={{ margin: 20, justifyContent: 'center' }}>
                    <Button variant='contained' color='primary' onClick={this.addExo}>{t('save')}</Button>
                </DialogActions>
            </Dialog>

            <Button style={{ marginBottom: 20 }} startIcon={<AddIcon />} variant='contained' color='primary' onClick={this.showExoForm}>{t('add')}</Button>
            {this.props.exercices.map((exercice: Exercice) =>
                <ExerciceCard
                    key={exercice.id}
                    exercice={exercice}
                    onClick={this.showEditExercice}
                    onPlay={(e: any) => { this.play(e, exercice.video.id) }}
                />
            )}
        </>
    }
}

export default withTranslation()(ExerciceScreen)