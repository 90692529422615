import Exercice from "./Exercice"
export default class Pathology {
    id: string
    refId: string
    userId: string
    area: string
    step: number
    exercices: Exercice[] 
    createdAt: number = 0
    date: number
    kineId: string

    constructor(id: string, refId: string, userId: string, kineId: string, area: string, step: number, exercices: Exercice[], date: number) {
        this.id = id;
        this.refId = refId;
        this.userId = userId;
        this.kineId = kineId;
        this.area = area;
        this.step = step;
        this.exercices = exercices;
        this.date = date;
    }

    static mapFromFIR(json: any) {
        return new Pathology(
            json.id,
            json.refId,
            json.userId,
            json.kineId,
            json.area,
            json.step,
            json.exercices,
            json.date,
        );
    }
}