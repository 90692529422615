import React from 'react';
import { Toolbar, Chip, TextField, Typography, Dialog, DialogContent, DialogTitle, FormControl, Select, MenuItem, Divider, Button, InputLabel, Card, CardContent, IconButton, GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import User from '../models/User';
import UserProfile from '../models/UserProfile';
import Profile from '../models/Profile';
import BodyArea from '../models/BodyArea';
import Pathology from '../models/Pathology';
import Exercice from '../models/Exercice';
import Video from '../models/Video';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

const firebase = require("firebase/app");
require("firebase/firestore");

export class UserCard extends React.Component<any> {

    state = {}

    toggleActive = (e: any) => {
        e.stopPropagation();
        const user = this.props.user;
        firebase.firestore().collection("users").doc(user.uid)
            .set({ active: !user.active }, { merge: true });
    }

    toggleKine = (e: any) => {
        e.stopPropagation();
        const user = this.props.user;
        firebase.firestore().collection("users").doc(user.uid)
            .set({ kine: !user.isKine }, { merge: true });
    }

    render() {
        const { user, onCardClick, areas, editPatho, i18n } = this.props;

        return (
            <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 275 }} onClick={onCardClick}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>{user.firstName} {user.lastName}</Typography>
                    <Typography color="textSecondary">{user.email}</Typography>
                    <Divider light={true} style={{ marginTop: 10, marginBottom: 10 }} />
                    <div style={{ margin: 10 }}>
                        {user.pathologies && user.pathologies.map((p: any) => <Chip
                            label={areas.filter((a: any) => a.id === p.area)[0].name}
                            key={p.id}
                            variant={p.step > 0 ? "outlined" : "default"}
                            style={{ margin: 5 }}
                            size="small"
                            color={p.step > 0 ? "default" : "primary"}
                            onClick={e => { e.stopPropagation(); editPatho(user, p) }}
                        />)}
                        {(!user.pathologies || user.pathologies.length === 0) && <i style={{ fontSize: "small" }}>{i18n.t('users.noPathology')}</i>}
                    </div>
                    <Divider light={true} />
                    <div>
                        {user.active ?
                            <Chip size="small" label="Activé" color="primary" style={{ margin: 10 }} onClick={this.toggleActive} /> :
                            <Chip size="small" label="Désactivé" color="default" style={{ margin: 10 }} onClick={this.toggleActive} />}
                        {user.isKine
                            ? <Chip size="small" label="Kiné" style={{ backgroundColor: '#4CAF50', marginRight: 10 }} onClick={this.toggleKine} />
                            : <Chip size="small" label="Kiné" style={{ marginRight: 10 }} color='default' onClick={this.toggleKine} />
                        }
                        {user.isAdmin && <Chip size="small" label="Admin" color="secondary" />}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

class UserScreen extends React.Component<any> {

    state = {
        users: [],
        exercices: [],
        searchText: "",
        open: false,
        userId: "",
        profiles: [],
        showPrefs: false,
        showPathos: false,
        updatePatho: false,
        patho: {} as any,
        err: "",
        showForm: false,
        videoUrl: '',
        user: {} as User
    }

    async componentDidMount() {
        const { users } = this.props;
        this.setState({ users });

        const docs = await firebase.firestore().collection("profiles").get();
        var profiles: Profile[] = [];
        docs.forEach((p: any) => {
            profiles.push(Profile.mapFromFIR(p.id, p.data()));
        });

        this.setState({ profiles });
    }

    componentDidUpdate(prevProps: any) {
        if (JSON.stringify(prevProps.users) !== JSON.stringify(this.props.users)) {
            const text = this.state.searchText;
            const users = this.props.users.filter((u: User) => text ? u.email.includes(text) : true);
            this.setState({ users });
        }
        if (JSON.stringify(prevProps.exercices) !== JSON.stringify(this.props.exercices)) {
            this.setState({ exercices: this.props.exercices });
        }
    }

    onChangeText = (event: any) => {
        const text = event.target.value;
        const filteredUsers = this.props.users.filter((u: User) => text ? u.email.includes(text) : true);
        this.setState({ users: filteredUsers, searchText: text });
    }

    addProfile = (user: User) => {
        const users = this.state.users;
        users.map((u: User) => {
            u.uid === user.uid && u.profiles.push(UserProfile.fake());
            return u;
        });
        this.setState({ users });
    }

    setUserProfile = (user: User, profile: Profile, option: string) => async () => {
        const profiles = user.profiles || {};
        // remove option if already set
        if (profiles[profile.id] === profile.options.indexOf(option))
            delete profiles[profile.id];
        else
            profiles[profile.id] = profile.options.indexOf(option);

        const doc = await firebase.firestore().collection("users").doc(user.uid).get();
        firebase.firestore().collection("users").doc(user.uid)
            .set({ ...doc.data(), profiles });

    }

    setPatho = (field: string, value: any) => {
        const patho: any = this.state.patho;
        patho[field] = value;
        patho.createdAt = Date.now();
        patho.userId = this.state.userId;
        this.setState({ patho });
    }

    savePrefs = async () => {
        this.hideForm();
    }

    savePatho = () => {
        const patho: any = this.state.patho;
        const t = this.props.i18n.t;

        if (patho.step === undefined || !patho.area || !patho.kineId) {
            this.setState({ err: t('users.mandatory') })
            return;
        }
        if (!patho.createdAt) {
            this.setState({ err: t('users.update') })
            return;
        }

        patho.id = `${Date.now()}`;
        patho.refId = patho.refId || patho.id;
        patho.exercices = patho.exercices?.map((e: Exercice) => e.id) || [];

        firebase.firestore().collection("pathologies").doc(patho.id).set(JSON.parse(JSON.stringify(patho)), { merge: true })
        this.hideForm();
    }

    hideForm = () => {
        this.setState({ patho: {}, err: "", updatePatho: false, showPathos: false, showPrefs: false, showForm: false })
    }

    editPatho = (user: User, p: Pathology) => {
        this.setState({ userId: user.uid, patho: p, showPathos: true, updatePatho: true })
    }

    showForm = (user: User) => {
        this.setState({ showForm: true, user });
    }

    saveUser = () => {
        const { user } = this.state;
        if (!user.lastName || !user.firstName || !user.email) return;

        const data = {
            firstName: user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1).toLowerCase(),
            lastName: user.lastName.toUpperCase(),
            email: user.email,
            notes: user.notes || "",
            active: true
        }

        if (user.uid)
            firebase.firestore().collection("users").doc(user.uid).set(data, { merge: true });
        else
            firebase.firestore().collection("users").add(data);

        this.setState({ showForm: false });
    }

    selectExo = (patho: Pathology, exercice: Exercice) => () => {
        var exercices = patho.exercices;
        const newExercices = exercices.filter(e => e.id !== exercice.id);
        // should add exo to patho
        if (exercices.length === newExercices.length)
            newExercices.push(exercice)
        // update exos
        patho.exercices = newExercices;
        patho.createdAt = Date.now();

        this.setState({ patho });
    }

    searchExo = (e: any) => {
        const search = e.target.value.toLowerCase();
        var results = this.props.exercices;

        if (!!search) {
            results = this.props.exercices.filter((e: Exercice) =>
                e.name.toLowerCase().includes(search) ||
                e.video.name.toLowerCase().includes(search) ||
                (e.video.tags || []).join(",").includes(search)
            );
        }

        this.setState({ exercices: results });
    }

    hideVideo = () => {
        this.setState({ videoUrl: '' })
    }

    play = (video: Video) => {
        if (!video || !video.id) return;

        var storageRef = firebase.storage().ref();
        const ref = storageRef.child(`videos/${video.id}_landscape.webm`);
        ref.getDownloadURL().then((url: string) => {
            this.setState({ videoUrl: url })
        });
    }

    showHistory = () => {

    }

    updateUser = (field: string) => (e: any) => {
        this.setState({ user: { ...this.state.user, [field]: e.target.value } });
    }

    render() {
        const { showForm, patho, videoUrl, user, users, exercices } = this.state;
        const { i18n } = this.props;

        return <div style={{ textAlign: "center" }}>
            <Dialog open={!!videoUrl} onBackdropClick={this.hideVideo} fullWidth>
                <video controls autoPlay loop>
                    <source src={videoUrl} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos
                </video>
            </Dialog>
            {this.state.users.length > 0 && (
                <span>
                    <TextField style={{ width: 275 }} placeholder={i18n.t('users.search')} onChange={this.onChangeText} value={this.state.searchText} />

                    <div style={{ margin: 20, marginBottom: 40 }}>
                        <Button startIcon={<AddIcon />} variant='contained' color='primary' onClick={() => { this.showForm({} as User) }}>Ajouter</Button>
                    </div>

                    {showForm && <Dialog open={this.state.showForm} onBackdropClick={this.hideForm} fullWidth>
                        <DialogContent style={{ textAlign: 'center' }}>
                            <div style={{ margin: 20 }}>
                                <Typography variant="h6" component="h2" color="primary">{i18n.t('users.lastName')}</Typography>
                                <TextField onChange={this.updateUser('lastName')} defaultValue={user.lastName} />
                            </div>
                            <div style={{ margin: 20 }}>
                                <Typography variant="h6" component="h2" color="primary">{i18n.t('users.firstName')}</Typography>
                                <TextField onChange={this.updateUser('firstName')} defaultValue={user.firstName} rowsMax={5} />
                            </div>
                            <div style={{ margin: 20 }}>
                                <Typography variant="h6" component="h2" color="primary">{i18n.t('users.email')}</Typography>
                                <TextField onChange={this.updateUser('email')} defaultValue={user.email} />
                            </div>
                            <div style={{ margin: 20 }}>
                                <Typography variant="h6" component="h2" color="primary">{i18n.t('users.notes')}</Typography>
                                <TextField multiline onChange={this.updateUser('notes')} defaultValue={user.notes} />
                            </div>
                            {!!user &&
                                <Button startIcon={<AddIcon />} variant="contained" color="default" onClick={() => { this.setState({ userId: user.uid, showPathos: true }) }} style={{ margin: 20, width: 'fit-content' }}>{i18n.t('users.pathology')}</Button>
                            }
                            {!!user?.pathologies && user.pathologies.length > 0 &&
                                <Button variant="outlined" color="default" onClick={this.showHistory} style={{ margin: 20, width: 'fit-content' }}>{i18n.t('users.seeHistory')}</Button>
                            }
                            <Button variant="contained" color="primary" onClick={this.saveUser} style={{ margin: 20, width: 'fit-content' }}>{i18n.t('save')}</Button>
                        </DialogContent>
                    </Dialog>}

                    {user && <Dialog open={this.state.showPrefs} onBackdropClick={() => { this.setState({ showPrefs: false }) }} fullWidth>
                        <Toolbar style={{ justifyContent: "flex-end" }}><IconButton edge="start" onClick={this.hideForm}><CloseIcon /></IconButton></Toolbar>
                        <div style={{ textAlign: "center", padding: 10, marginTop: -40, alignItems: 'center' }}>
                            <DialogTitle>{i18n.t('users.prefs')}</DialogTitle>
                            <Typography color="textSecondary" style={{ marginBottom: 10 }} >{user.email}</Typography>
                            <Divider light style={{ margin: 10 }} />
                            {this.state.profiles.length > 0 && this.state.profiles.map((p: Profile) =>
                                <span key={p.id}>
                                    <Typography variant="h6" component="h2">{p.name}</Typography>
                                    <div style={{ margin: 10 }}>
                                        {p.options.length > 0 && p.options.map(o => {
                                            const value = user.profiles && user.profiles[p.id];
                                            const style = value === p.options.indexOf(o) ? "default" : "outlined";
                                            return <Chip key={`${p.id}-${o}`} size="small" label={o} color="primary" variant={style} style={{ margin: 5 }} onClick={this.setUserProfile(user, p, o)} />;
                                        })}
                                    </div>
                                </span>
                            )}
                            <Button variant="contained" color="primary" onClick={this.savePrefs} style={{ margin: 20, width: 'fit-content' }}>{i18n.t('save')}</Button>
                        </div>
                    </Dialog>}

                    {user && this.state.showPathos &&
                        <Dialog open={this.state.showPathos} onBackdropClick={this.hideForm} fullWidth>
                            <DialogContent style={{ textAlign: "center" }}>
                                {this.state.err && <i style={{ color: "red" }}>{this.state.err}</i>}
                                <i>0 {i18n.t('users.session')}</i>
                                <br/><br/>
                                <div><Button startIcon={<AddIcon />} variant="contained" color="default" onClick={() => { }}>{i18n.t('exercices.session')}</Button></div>
                                <div style={{ margin: 20 }}>
                                    <Typography variant="h6" component="h2" color="primary">{i18n.t('users.date')}</Typography>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="DD/MM/yyyy"
                                            margin="normal"
                                            defaultValue={patho.date || new Date()}
                                            value={patho.date}
                                            onChange={(e: any) => { this.setPatho('date', moment(e).valueOf()) }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div style={{ margin: 20 }}>
                                    <Typography variant="h6" component="h2" color="primary">{i18n.t('kine')}</Typography>
                                    <Select onChange={(e: any) => { this.setPatho('kineId', e.target.value) }} value={patho.kineId || ''} style={{ minWidth: 120 }}>
                                        <MenuItem key='' value=''></MenuItem>
                                        {this.state.users.filter((u: User) => u.isKine).map((k: User) =>
                                            <MenuItem key={k.uid} value={k.uid}>{k.firstName} {k.lastName}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                                <div style={{ margin: 20 }}>
                                    <Typography variant="h6" component="h2" color="primary">{i18n.t('areas.step')}</Typography>
                                    <div style={{ margin: 10 }}>
                                        <Chip size="small" label="Crise" color="primary" variant={(patho as any).step === 1 ? "default" : "outlined"} style={{ marginLeft: 10 }} onClick={() => { this.setPatho('step', 1) }} />
                                        <Chip size="small" label="Récupération" color="primary" variant={(patho as any).step === 2 ? "default" : "outlined"} style={{ marginLeft: 10 }} onClick={() => { this.setPatho('step', 2) }} />
                                        <Chip size="small" label="Performance" color="primary" variant={(patho as any).step === 3 ? "default" : "outlined"} style={{ marginLeft: 10 }} onClick={() => { this.setPatho('step', 3) }} />
                                        {this.state.updatePatho && <Chip size="small" label="Guéri" color="primary" variant={(patho as any).step === 0 ? "default" : "outlined"} style={{ marginLeft: 10 }} onClick={() => { this.setPatho('step', 0) }} />}
                                    </div>
                                </div>
                                <div style={{ margin: 20 }}>
                                    <Typography variant="h6" component="h2" color="primary">{i18n.t('users.pain')}</Typography>
                                    <FormControl>
                                        <InputLabel>{i18n.t('users.area')}</InputLabel>
                                        <Select onChange={(e: any) => { this.setPatho('area', e.target.value) }} value={(patho as any).area || ''} style={{ minWidth: 120 }}>
                                            <MenuItem key='' value=''></MenuItem>
                                            {this.props.areas.map((area: BodyArea) =>
                                                <MenuItem key={area.name} value={area.id} >{area.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ margin: 20 }}>
                                    <Typography variant="h6" component="h2" color="primary">{i18n.t('exercices.title')}</Typography>
                                    <TextField label={i18n.t('users.searchExo')} style={{ width: 250 }} onChange={this.searchExo} />
                                    <GridList cellHeight={150} style={{ marginTop: 20 }}>
                                        {exercices.sort((a: Exercice, b: Exercice) => a.name.localeCompare(b.name)).map((e: Exercice) => (
                                            <GridListTile
                                                style={{ border: patho.exercices && patho.exercices.filter((exo: Exercice) => exo.id === e.id).length > 0 ? `0.1em solid orange` : undefined }}
                                                onClick={this.selectExo(patho, e)}
                                            >
                                                <img src={e.video.thumbnail} alt={e.video.id} />
                                                <GridListTileBar
                                                    title={e.name}
                                                    subtitle={<span><b>{e.video.name}</b> #{e.video.tags.join(" #")}</span>}
                                                    actionIcon={<IconButton onClick={(evt: any) => { this.play(e.video); evt.stopPropagation(); }}><PlayArrowIcon /></IconButton>}
                                                    style={{ textAlign: "left" }}
                                                />
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </div>
                                <Button variant="contained" color="primary" onClick={this.savePatho} style={{ margin: 20 }}>{i18n.t('save')}</Button>
                            </DialogContent>
                        </Dialog>}
                    {this.state.users.map((user: User) => (
                        <UserCard
                            key={user.uid}
                            areas={this.props.areas}
                            user={user}
                            i18n={i18n}
                            users={users}
                            showPrefs={() => { this.setState({ userId: user.uid, showPrefs: true }) }}
                            editPatho={(u: any, p: any) => { this.editPatho(u, p) }}
                            onCardClick={() => { this.showForm(user); }}
                        />)
                    )}
                </span>
            )
            }
        </div>;
    }
}

export default withTranslation()(UserScreen);