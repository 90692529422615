import React from 'react';

import { Fab } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import TranslateIcon from '@material-ui/icons/Translate';

import User from './models/User';
import BodyArea from './models/BodyArea';
import UserScreen from './views/UserScreen';
import AuthScreen from './views/AuthScreen';
import ExerciceScreen from './views/ExerciceScreen';
import HomeScreen from './views/HomeScreen';
import HistoryScreen from './views/HistoryScreen';
import AreaScreen from './views/AreaScreen';
import Pathology from './models/Pathology';
import Exercice from './models/Exercice';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import VideoScreen from './views/VideoScreen';
import Video from './models/Video';

import { withTranslation } from "react-i18next";

var firebase = require("firebase/app");
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

firebase.initializeApp({
  apiKey: 'AIzaSyCp0YXuUMagyOPFHN0WM_qVbe2gNKRNFeE',
  authDomain: 'yb-app-e0f70.firebaseapp.com',
  projectId: "yb-app-e0f70",
  appId: "1:530569977516:web:bbf1ac18d80e22dcf48909",
  storageBucket: 'yb-app-e0f70.appspot.com',
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#FDB000",
    },
    secondary: {
      main: '#E70700',
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        backgroundColor: '#1C252C',
      }
    },
  },
});

export enum MenuType {
  Areas = "/areas",
  Videos = "/videos",
  Users = "/users",
  Exercices = "/exercices",
  History = "/history",
  Home = "/"
}

declare global {
  interface Window { xToken: any; }
}

class App extends React.Component<any> {
  state = {
    users: [],
    areas: [],
    pathologies: [],
    exercices: [],
    config: [],
    videos: [],
    displayScreen: MenuType.Home,
    showMenu: false,
    isAuth: undefined,
    displayLang: false,
  }

  anchorEl = null;

  async componentDidMount() {

    if (window.xToken)
      this.authFromXToken(window.xToken);

    firebase.auth().onAuthStateChanged((user: any) => {
      if (!user) {
        this.setState({ isAuth: false });
        return;
      }
      this.setState({ isAuth: true });
      this.getSettings();
      this.getBodyAreas();
      this.getVideos((videos: Video[]) => {
        this.getExercices(videos, (exercices: Exercice[]) => {
          this.getUsers(exercices);
        });
      });
    });
  }

  authFromXToken = (token: string) => {
    const options = {
      method: 'GET',
      headers: { "x-token": `${token}` }
    };

    fetch("https://us-central1-yb-app-e0f70.cloudfunctions.net/api/auth", options)
      .then(res => res.json())
      .then(json => firebase.auth().signInWithCustomToken(json.token));
  }

  getUsers = (exercices: Exercice[]) => {
    var db = firebase.firestore();
    db.collection("users").onSnapshot((docs: any) => {
      var users: any = {};
      docs.forEach((doc: any) => {
        const data = doc.data();
        users[doc.id] = User.mapFromFIR({ ...data, uid: doc.id });
      });

      db.collection("pathologies").onSnapshot((docs: any) => {
        // reinit users pathos
        Object.keys(users).forEach(id => { users[id].pathologies = [] });

        var newPathos: any = {};
        docs.forEach((doc: any) => {
          const data = doc.data();
          // keep only the most recent patho
          if (!newPathos[data.refId] || newPathos[data.refId].createdAt < data.createdAt) {
            const exos = !!data.exercices ? exercices.filter(e => data.exercices.includes(e.id)) : [];
            newPathos[data.refId] = { ...data, id: doc.id, exercices: exos };
          }
        });

        Object.keys(newPathos).forEach(id => {
          const np = newPathos[id];
          const patho = Pathology.mapFromFIR(np);
          users[np.userId] && users[np.userId].pathologies.push(patho);
        });

        this.setState({ users: Object.values(users) });
      });
    });
  }

  getBodyAreas = () => {
    var db = firebase.firestore();
    db.collection("body_area").onSnapshot((docs: any) => {
      var areas: BodyArea[] = [];
      docs.forEach((doc: any) => {
        const data = doc.data();
        areas.push(BodyArea.mapFromFIR({ ...data, id: doc.id }));
      });
      this.setState({ areas });
    });
  }

  getExercices = (videos: Video[], callback: (exercices: Exercice[]) => void) => {
    var db = firebase.firestore();
    db.collection("exercices").onSnapshot((docs: any) => {
      var exercices: Exercice[] = [];
      docs.forEach((doc: any) => {
        const data = doc.data();
        const video = videos.filter(v => v.id === data.videoId)[0];
        exercices.push(Exercice.mapFromFIR({ ...data, id: doc.id, video }));
      });
      callback(exercices);
      this.setState({ exercices });
    });
  }

  getVideos = (callback: (videos: Video[]) => void) => {
    var db = firebase.firestore();
    db.collection("videos").onSnapshot((docs: any) => {
      var videos: Video[] = [];
      docs.forEach((doc: any) => {
        const data = doc.data();
        videos.push(Video.mapFromFIR({ ...data, id: doc.id }));
      });
      callback(videos);
      this.setState({ videos });
    });
  }

  getSettings = () => {
    var db = firebase.firestore();
    db.collection("settings").onSnapshot((docs: any) => {
      var config: any;
      docs.forEach((doc: any) => {
        if (doc.id === "config")
          config = doc.data();
      });
      this.setState({ config });
    });
  }

  menuClick = (type: MenuType) => () => {
    this.setState({ displayScreen: type, showMenu: false });
    window.location.href = type;
  }

  showMenu = (e: any) => {
    this.anchorEl = e.currentTarget;
    this.setState({ showMenu: true });
  }

  hideMenu = () => {
    this.anchorEl = null;
    this.setState({ showMenu: false });
  }

  logout = () => {
    firebase.auth().signOut();
  }

  toggleLang = () => {
    this.setState({ displayLang: !this.state.displayLang });
  }

  changeLang = (lang: string) => () => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ displayLang: false });
  }

  render() {
    const { pathologies, videos, exercices, users, areas, isAuth, displayLang, config } = this.state;
    const { i18n } = this.props;

    if (isAuth === undefined)
      return null;
    if (!isAuth)
      return <AuthScreen />

    return <ThemeProvider theme={theme}>
      <Router>
        <Fab
          onClick={this.menuClick(MenuType.Home)}
          size="large"
          style={{ position: 'fixed', top: 20, left: 20 }}
        >
          <HomeIcon />
        </Fab>
        <Fab
          onClick={this.logout}
          size="large"
          style={{ position: 'fixed', top: 20, right: 20 }}
        >
          <ExitToAppIcon color="secondary" />
        </Fab>

        <Fab onClick={this.toggleLang} style={{ position: 'fixed', bottom: 20, left: 20 }} >
          <TranslateIcon fontSize="large" />
          <img src={`/${i18n.language}.png`} width="50%" style={{ position: "absolute", width: 20, right: -5, bottom: -5 }} />
        </Fab>
        {displayLang && <>
          <Fab onClick={this.changeLang('fr')} style={{ position: 'fixed', bottom: 180, left: 20 }} >
            <img src="/fr.png" width="100%" />
          </Fab>
          <Fab onClick={this.changeLang('en')} style={{ position: 'fixed', bottom: 100, left: 20 }} >
            <img src="/en.png" width="100%" />
          </Fab>
          <Fab onClick={this.changeLang('it')} style={{ position: 'fixed', bottom: 260, left: 20 }} >
            <img src="/it.png" width="100%" />
          </Fab>
        </>}
        <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: 20, marginTop: 40 }}>
          <Switch>
            <Route exact path={MenuType.Home}>
              <HomeScreen />
            </Route>
            <Route path={MenuType.Areas}>
              <AreaScreen />
            </Route>
            <Route path={MenuType.Users}>
              <UserScreen users={users} areas={areas} exercices={exercices} videos={videos} />
            </Route>
            <Route path={MenuType.Exercices}>
              <ExerciceScreen exercices={exercices} />
            </Route>
            <Route path={MenuType.Videos}>
              <VideoScreen videos={videos} config={config}/>
            </Route>
            <Route path={MenuType.History}>
              <HistoryScreen pathologies={pathologies} />
            </Route>
          </Switch>
        </span>
      </Router>
    </ThemeProvider>
      ;
  }
}

export default withTranslation()(App);