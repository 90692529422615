import Pathology from "./Pathology"

export default class User {
    uid: string
    email: string
    active: boolean
    firstName: string
    lastName: string
    isAdmin: boolean
    isKine: boolean
    notes: string
    profiles: any
    pathologies: Pathology[] = []

    constructor(uid: string, email: string, active: boolean, admin: boolean, kine: boolean, notes: string, lastName: string, firstName: string, profiles: any) {
        this.uid = uid;
        this.email = email;
        this.active = active;
        this.isAdmin = admin;
        this.isKine = kine;
        this.notes = notes;
        this.firstName = firstName;
        this.lastName = lastName;
        this.profiles = profiles;
    }

    static mapFromFIR(json: any) {        
        return new User(
            json.uid,
            json.email,
            json.active,
            json.admin,
            json.kine,
            json.notes,
            json.lastName,
            json.firstName,
            json.profiles
        );
    }
}