import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Chip, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VideoProcessing from '../services/VideoProcessing';
import Video from 'src/models/Video';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class VideoForm extends React.Component<any> {
    file_input: HTMLInputElement | null = null;
    video_input: HTMLVideoElement | null = null;
    _name: string = '';

    state = {
        video: {} as Video,
        content: "",
        showDialog: false,
        showLoader: false
    }

    constructor(props: any) {
        super(props);

        this.state = {
            content: "",
            showDialog: false,
            showLoader: false,
            video: props.video || {} as Video,
        };
    }

    selectFile = () => {
        this.file_input?.click();
    }

    parseVideo = (i18n: any) => (e: any) => {
        const video: any = Object.values(e.target.files)[0];

        const type = video.type;
        const extension = video.name.split(".").slice(-1)[0];
        const name = video.name.replace(`.${extension}`, "").replace(/[^a-zA-Z]/g, "");
        const title = name;
        const length = Math.round((video.size / 1024 / 1024 + Number.EPSILON) * 100) / 100;
        const video_url = URL.createObjectURL(video);
        
        if (length > 60) {
            alert(i18n.t("videos.tooLarge"));
            return;
        }

        this.setState({ video: { ...video, updatedAt: moment().valueOf(), name, length, title } });

        this.video_input?.setAttribute('src', video_url);

        const scope = this;
        var reader = new FileReader();
        reader.onload = (f: any) => {
            scope.setState({ content: `data:${type};base64,${btoa(f.target.result)}` });
        }
        reader.readAsBinaryString(video);
    }

    processVideo = () => {
        const { content, video } = this.state;

        // hide popup
        this.setState({ showLoader: true, showDialog: false });

        const processing = new VideoProcessing(video, content);

        processing.uploadToCloudinary((res: any) => {
            processing.saveDataToFIR();
            processing.uploadToFIRStorage(() => {
                this.props.hideForm();
            });
        });
    }

    onTitleChange = (event: any) => {
        const title = event.target.value;
        this.setState({ video: { ...this.state.video, title } });
    }

    onTagsChange = (e: any, value: any) => {
        this.setState({ video: { ...this.state.video, tags: value } });
    }

    deleteTag = (tag: string) => {
        const tags = this.state.video.tags.filter(t => t !== tag);
        this.setState({ video: { ...this.state.video, tags } });
    }

    render() {
        const { config, i18n } = this.props;
        const { video, showLoader } = this.state;
        const tags = config.tags;

        return <>
            <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={showLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={this.state.showDialog} onClose={() => { this.setState({ showDialog: false }) }}>
                <DialogTitle>{i18n.t('videos.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: "justify" }}>
                        {i18n.t('videos.convert')}<br /><br />{i18n.t('videos.wait')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ showDialog: false }) }} color="secondary">{i18n.t('cancel')}</Button>
                    <Button onClick={this.processVideo} color="primary" autoFocus>{i18n.t('validate')}</Button>
                </DialogActions>
            </Dialog>
            <span style={{ textAlign: 'center' }}>
                {(!video || Object.keys(video).length === 0) && <Button variant="contained" color="primary" onClick={this.selectFile} style={{ marginBottom: 20 }}>{i18n.t('videos.choose')}</Button>}
                <input
                    ref={e => { this.file_input = e }}
                    type="file"
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={this.parseVideo(i18n)} />
                {this.video_input && (
                    <video
                        ref={e => { this.video_input = e }}
                        controls
                        style={{ width: "300px" }}
                        onLoadedMetadata={(e: any) => {
                            this.setState({
                                video: {
                                    ...video,
                                    width: e.target.videoWidth,
                                    height: e.target.videoHeight,
                                    duration: e.target.duration,
                                }
                            });
                        }}
                    ><source /></video>
                )}
                <>
                    <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('name')}</Typography>
                        {video.name}
                    </div>
                    <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('title')}</Typography>
                        <TextField variant="standard" value={video.title} onChange={this.onTitleChange} />
                    </div>
                    <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('tags')}</Typography>
                        <Autocomplete
                            multiple
                            options={tags}
                            renderInput={(params) => <TextField {...params} variant="standard" label={i18n.t('videos.categorize')} />}
                            onChange={this.onTagsChange}
                        />
                        <br />
                        {(video.tags || []).map((t: any) => <Chip
                            key={t}
                            size="small"
                            label={t}
                            variant="default"
                            color="primary"
                            onDelete={() => { this.deleteTag(t) }}
                            style={{ marginTop: 5, marginRight: 5 }}
                        />)}
                    </div>
                    {!!video.type && <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('videos.type')}</Typography>
                        {video.type}
                    </div>}
                    {!!video.width && !!video.height && <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('videos.dimensions')}</Typography>
                        {video.width}x{video.height}
                    </div>}
                    {!!video.length && <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('videos.size')}</Typography>
                        {video.length}
                    </div>}
                    {!!video.duration && <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('videos.duration')}</Typography>
                        {video.duration}
                    </div>}
                    {!!video.updatedAt && <div style={{ margin: 10 }}>
                        <Typography variant="h6" component="h2" color="primary">{i18n.t('videos.updatedAt')}</Typography>
                        {moment(video.updatedAt).format("DD/MM/YYYY à HH:mm")}
                    </div>}
                    <br />
                    <Button variant="contained" disabled={!video.name} onClick={() => { this.setState({ showDialog: true }) }} >{i18n.t('save')}</Button>
                </>
            </span>
        </>;
    }
}

export default withTranslation()(VideoForm);