
import React from 'react';
import Pathology from 'src/models/Pathology';

export default class HistoryScreen extends React.Component<any> {

    render() {
        const { pathologies } = this.props;

        return pathologies.map((p: Pathology) => <span>test</span>);
    }
}