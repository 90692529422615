import Video from "src/models/Video";

var firebase = require("firebase/app");
require("firebase/storage");

var crypto = require('crypto')
const moment = require('moment');

const cloud_name = 'dev-ybapp';
const api_key = '712673574344811';
const api_secret = 'fL4v_85g62-sQN_rtloL9hLCgwM';

const CLOUDINARY_BASE_URL = `https://res.cloudinary.com/${cloud_name}/video/upload`;
const CLOUDINARY_API_URL = `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`;
const CLOUDINARY_DELETE_URL = `https://api.cloudinary.com/v1_1/${cloud_name}/video/destroy`;

//115703606
const folder = "app";

export default class VideoProcessing {
    video: Video
    file: string
    thumbnail: any
    cid?: string // cloudinary public id

    get cloudinaryImageUrl(): string {
        return `${CLOUDINARY_BASE_URL}/${this.cid}`;
    }

    get landscapeImageUrl(): string {
        return `${CLOUDINARY_BASE_URL}/t_landscape_webm/${folder}/${this.video.name}`;
    }

    get portraitImageUrl(): string {
        return `${CLOUDINARY_BASE_URL}/t_portrait_webm/${folder}/${this.video.name}`;
    }

    get deleteImageUrl(): string {
        return `https://${api_key}:${api_secret}@api.cloudinary.com/v1_1/${cloud_name}/resources/image/upload?public_ids[]=${this.video.name}`;
    }

    constructor(video: Video, file: string) {
        this.video = video;
        this.file = file;
    }

    // upload {landscape + portrait} to FIR Storage
    uploadToFIRStorage = (callback: () => void) => {
        var storageRef = firebase.storage().ref();

        fetch(this.portraitImageUrl)
            .then(r => r.blob())
            .then(r => {
                const portraitRef = storageRef.child(`videos/${this.video.name}_portrait.webm`);
                portraitRef.put(r)
                    .then((res: any) => {
                        console.log('Uploaded portrait file')
                        fetch(this.landscapeImageUrl)
                            .then(r => r.blob())
                            .then(r => {
                                const landscapeRef = storageRef.child(`videos/${this.video.name}_landscape.webm`);
                                landscapeRef.put(r)
                                    .then((res: any) => {
                                        console.log('Uploaded landscape file');
                                        callback();
                                    })
                                    .catch((err: any) => console.log(err))
                            });
                    })
                    .catch((err: any) => console.log(err))
            });


    }

    saveDataToFIR = () => {
        // save references to FIR Storage
        var db = firebase.firestore();
        const video = this.video;
        video.updatedAt = moment().valueOf();

        if (this.thumbnail)
            video.thumbnail = this.thumbnail;

        db.collection("videos")
            .doc(this.video.name)
            .set(video, { merge: true })
            ;
    }

    uploadToCloudinary(callback: (res: any) => void) {
        const timestamp = Date.now();
        var parameters = `folder=${folder}&public_id=${this.video.name}&timestamp=${timestamp}`;
        parameters += api_secret;
        const signature = crypto.createHash('sha1').update(parameters).digest('hex');

        var formdata = new FormData();
        formdata.append("api_key", api_key);
        formdata.append("signature", signature);
        formdata.append("public_id", this.video.name);
        formdata.append("timestamp", `${timestamp}`);
        formdata.append("file", this.file);
        formdata.append("folder", `${folder}`);

        const options = {
            method: 'POST',
            body: formdata
        };

        fetch(CLOUDINARY_API_URL, options)
            .then((res: any) => res.json())
            .then((res: any) => {
                this.cid = res.public_id;

                const thumbnailURL: string = res.secure_url.replace(/\.[^.]+$/, '.jpg');
                fetch(thumbnailURL)
                    .then((res: any) => res.blob())
                    .then(blob => {
                        var reader = new FileReader();
                        reader.onload = () => {
                            this.thumbnail = reader.result;
                            callback(res);
                        };
                        reader.readAsDataURL(blob);
                    })
            })
            .catch((err: any) => callback(err));
        ;
    }

    static deleteFromCloudinary(name: string) {
        const timestamp = Date.now();
        var parameters = `public_id=${name}&timestamp=${timestamp}`;
        parameters += api_secret;
        const signature = crypto.createHash('sha1').update(parameters).digest('hex');

        var formdata = new FormData();
        formdata.append("api_key", api_key);
        formdata.append("public_id", name);
        formdata.append("signature", signature);
        formdata.append("timestamp", `${timestamp}`);

        const options = {
            method: 'POST',
            body: formdata
        };
        fetch(CLOUDINARY_DELETE_URL, options)
        ;
    }
}