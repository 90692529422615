import React from 'react';
import { Button, Dialog, Card, CardMedia, CardContent, Typography, Chip, IconButton, Toolbar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Video from '../models/Video';
import VideoForm from './VideoForm';
import { withTranslation } from 'react-i18next';

var firebase = require("firebase/app");
const moment = require("moment");

export class VideoCard extends React.Component<any> {
    state = {}

    remove = (t: any, video: Video) => (e: any) => {
        e.stopPropagation();
        if (window.confirm(t("confirm"))) {
            firebase.firestore().collection("videos").doc(video.name).delete();
            //@todo rm from fir + cloudinary if exists
        }
    }

    render() {
        const { video, onPlay, onEdit, t } = this.props;
        const date = video.updatedAt ? moment(video.updatedAt).format("DD/MM/YYYY à HH:mm") : "-";
        const tags = video.tags || [];

        return <Card variant="outlined" style={{ textAlign: "center", marginTop: 20, width: 275, cursor: 'pointer' }}>
            <div style={{ position: "absolute" }}>
                <Button variant="text" size="small" color="secondary" onClick={this.remove(t, video)}><DeleteIcon /></Button>
            </div>
            <CardMedia image={video.thumbnail} style={{ height: 150 }} onClick={onPlay} />
            <CardContent style={{ marginBottom: -20 }} onClick={onEdit} >
                <Typography style={{ marginTop: -10 }} variant="h4" component="h1" gutterBottom>{video.title}</Typography>
                <div style={{ margin: 10 }}>
                    <Chip size="small" label={date} variant="default" color="default" />
                </div>
                <div style={{ margin: 10 }}>
                    {tags.map((ta: any) => <Chip key={ta} size="small" label={ta} variant="default" color="primary" style={{ margin: 5 }} />)}
                </div>
            </CardContent>
        </Card>;
    }
}

class VideoScreen extends React.Component<any> {
    state = {
        videoUrl: '',
        showVideoForm: false,
        video: undefined,
    }

    hideVideoForm = () => {
        this.setState({ showVideoForm: false });
    }

    showVideoForm = () => {
        this.setState({ showVideoForm: true, video: {} as Video });
    }

    play = (video: Video) => () => {
        var storageRef = firebase.storage().ref();
        const ref = storageRef.child(`videos/${video.name}_landscape.webm`);
        ref.getDownloadURL().then((url: string) => {
            this.setState({ videoUrl: url })
        });
    }

    edit = (video: Video) => () => {
        this.setState({ showVideoForm: true, video });
    }

    hideVideo = () => {
        this.setState({ videoUrl: '' })
    }

    render() {
        const { videos, t, config } = this.props;
        const { videoUrl, video } = this.state;

        return <>
            <Dialog open={!!videoUrl} onBackdropClick={this.hideVideo} fullWidth>
                <video controls autoPlay loop>
                    <source src={videoUrl} type="video/mp4" />
                    {t("videos.incompatible")}
                </video>
            </Dialog>
            {this.state.showVideoForm &&
                <Dialog open={this.state.showVideoForm} onBackdropClick={this.hideVideoForm} fullWidth>
                    <Toolbar style={{ justifyContent: "flex-end" }}><IconButton edge="start" onClick={this.hideVideoForm}><CloseIcon /></IconButton></Toolbar>
                    <div style={{ padding: 20, textAlign: 'center' }}>
                        <VideoForm 
                            videos={videos}
                            config={config}
                            hideForm={this.hideVideoForm}
                            video={video}
                        />
                    </div>
                </Dialog>
            }

            <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={this.showVideoForm} style={{ marginBottom: 40 }}>{t('add')}</Button>

            {videos.map((video: Video) => {
                return !!video.disable ? null : <VideoCard key={video.id} video={video} onPlay={this.play(video)} onEdit={this.edit(video)} t={t} />;
            })}
        </>
    }
}

export default withTranslation()(VideoScreen);