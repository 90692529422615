export default class Profile {
    id: string
    name: string
    options: string[]

    constructor(id: string, name: string, options: string[]) {
        this.id = id;
        this.name = name;
        this.options = options;
    }

    static mapFromFIR(id: string, json: any) {
        return new Profile(
            id,
            json.name,
            json.options,
        );
    }
}