export default class BodyArea {
    id: string
    name: string

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static mapFromFIR(json: any) {
        return new BodyArea(
            json.id,
            json.name,
        );
    }
}