import React from 'react';
import { Button, Select, MenuItem, List, ListItem, ListItemIcon, ListItemText, CircularProgress } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { withTranslation } from 'react-i18next';

const firebase = require("firebase/app");

class AreaScreen extends React.Component<any> {

    state = {
        displayPopup: false,
        imageUrl: "",
        width: 0, height: 0,
        naturalWidth: 0, naturalHeight: 0,
        originX: 0,
        selectedButton: null,
        areas: [],
        sports: [],
        sport: Object(),
        progress: false
    }

    componentDidMount() {
        this.getBodyAreas(() => {
            this.getSports();
        });
    }

    getSports = () => {
        var db = firebase.firestore();
        db.collection("body_map").onSnapshot((docs: any) => {
            var sports: any[] = [];
            docs.forEach((doc: any) => sports.push({ ...doc.data(), id: doc.id }));
            this.setState({ sports });

            this.selectSport(sports[0]);
        });
    }

    getBodyAreas = (callback: () => void) => {
        var db = firebase.firestore();
        db.collection("body_area").onSnapshot((docs: any) => {
            var areas: string[] = [];
            docs.forEach((doc: any) => {
                areas.push({ ...doc.data(), id: doc.id });
            });
            this.setState({ areas });
            callback();
        });
    }

    selectSport = (sport: any) => {
        this.setState({ sport: sport });
        firebase.storage().ref('').child(sport.url).getDownloadURL().then((url: string) => {
            this.setState({ imageUrl: url });
        });
    }

    clickImage = (event: any) => {
        event.persist();

        const clickX = event.clientX;
        const clickY = event.clientY;
        const imgWidth = event.target.naturalWidth;
        const imgHeight = event.target.naturalHeight;
        const originX = event.target.x;
        const originY = event.target.y;

        const imageClickX = Math.floor((clickX - originX) * imgWidth / event.target.width);
        const imageClickY = Math.floor((clickY - originY) * imgHeight / event.target.height);
        const buttons = this.state.sport.buttons;
        const newButton = { x: imageClickX, y: imageClickY, area: "" };

        // add button if not present or very near another one
        const squareForbidden = 25;
        if (buttons.filter((b: any) => b.x > newButton.x - squareForbidden && b.x < newButton.x + squareForbidden && b.y > newButton.y - squareForbidden && b.y < newButton.y + squareForbidden).length === 0) {
            buttons.unshift(newButton);
            this.setState({ sport: { ...this.state.sport, buttons } });
        }
    }

    deleteButton = (button: { x: number, y: number }) => {
        const buttons = this.state.sport.buttons.filter((b: any) => b !== button);
        this.setState({ sport: { ...this.state.sport, buttons } });
    }

    onImgLoad = (event: any) => {
        this.setState({
            height: event.target.offsetHeight, width: event.target.offsetWidth,
            naturalHeight: event.target.naturalHeight, naturalWidth: event.target.naturalWidth,
            originX: event.target.x
        });
    }

    onMouseEnter = (button: any) => {
        this.setState({ selectedButton: button });
    }

    onMouseLeave = () => {
        this.setState({ selectedButton: null });
    }

    editButtonArea = (area: any, button: { x: number, y: number, area: any }) => {
        const buttons = this.state.sport.buttons;
        buttons.map((b: any) => {
            if (b.x === button.x && b.y === button.y)
                b.area = area.id;
            return b;
        });
        this.setState({ sport: { ...this.state.sport, buttons } });
    }

    save = () => {
        this.setState({ progress: true });
        const sport = this.state.sport;
        const i18n = this.props.i18n;

        const emptyButtons = sport.buttons.filter((b: any) => b.area === "");
        if (emptyButtons.length > 0) {
            alert(i18n.t('areas.selectBodyAreas'));
            return;
        }

        var db = firebase.firestore();
        db.collection("body_map").doc(sport.id)
            .set({ buttons: sport.buttons }, { merge: true })
            .then(() => this.setState({ progress: false }))
            .catch(() => this.setState({ progress: false }))
            ;
    }

    render() {
        const { areas, sports, sport } = this.state;
        const { i18n } = this.props;

        return sport.buttons ? (
            <span style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ margin: 10 }}>
                            <p><b>{i18n.t('areas.chooseImage')}</b></p>
                            <Select value={sport.name || ''} >
                                {sports.map((item: any) => <MenuItem key={item.name} value={item.name} onClick={() => { this.selectSport(item) }}>{item.name}</MenuItem>)}
                            </Select>
                        </div>
                        <div style={{ margin: 10 }}>
                            <b>{i18n.t('areas.sizeImage')}</b>
                            <p>{`L: ${this.state.naturalWidth}, H: ${this.state.naturalHeight}`}</p>
                        </div>
                    </div>
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                        <span>
                            <List>
                                {sport.buttons.map((button: { x: number, y: number, area: any }) =>
                                    < ListItem button
                                        key={`${button.x},${button.y}`}
                                        onMouseEnter={() => { this.onMouseEnter(button) }}
                                        onMouseLeave={this.onMouseEnter}
                                    >
                                        <ListItemText >
                                            <Select value={button.area || ''} style={{ minWidth: 100 }}>
                                                {areas.map((a: any) => <MenuItem key={a.id} value={a.id} onClick={() => { this.editButtonArea(a, button) }}>{a.name}</MenuItem>)}
                                            </Select>
                                            <br />
                                            <small>{`${button.x},${button.y}`}</small>
                                        </ListItemText>
                                        <ListItemIcon ><DeleteForeverIcon onClick={() => { this.deleteButton(button) }} /></ListItemIcon>
                                    </ListItem>
                                )}
                            </List>
                        </span>
                        <img style={{ border: "1px solid #FDB000", borderRadius: 20, height: 300 }} src={this.state.imageUrl} onClick={this.clickImage} onLoad={this.onImgLoad} alt="Selected" />
                        <svg
                            viewBox={`0 0 ${this.state.naturalWidth} ${this.state.naturalHeight}`}
                            style={{
                                width: this.state.width, height: this.state.height,
                                position: 'absolute', left: this.state.originX,
                                pointerEvents: 'none'
                            }}
                        >
                            {sport.buttons.map((button: { x: number, y: number }) => {
                                const color = button === this.state.selectedButton ? '#339DF0' : '#FDB000'
                                return (
                                    <g
                                        key={`${button.x},${button.y}`}
                                        transform={`translate(${button.x}, ${button.y})`}
                                    >
                                        <circle stroke={color} strokeWidth="4" r="24"></circle>
                                        <circle fill={color} r="14"></circle>
                                    </g>
                                )
                            }
                            )}
                        </svg>
                    </span>
                </span>
                <Button variant="contained" color="primary" onClick={this.save} >{this.state.progress && <CircularProgress />} {i18n.t('save')}</Button>
            </span >
        ) : null
    }
}

export default withTranslation()(AreaScreen)